<template>
  <div>
    <b-modal
      id="error-list-modal"
      title="ErrorList"
      cancel-disabled
      ok-only
      ok-title="Close"
    >
      <DataTable :value="errorRows" :scrollable="true" scrollHeight="200px">
        <Column field="row" header="Row" headerStyle="width: 80px"></Column>
        <Column field="error" header="Error"></Column>
      </DataTable>
    </b-modal>
    <b-modal
      id="license-override-modal"
      title="ライセンスは既に登録されています。上書き又は新規登録をしますか。"
      cancel-disabled
      ok-only
      size="xl"
      ok-title="Close"
    >
      <b-table
        show-empty
        :items="licenseOverrides"
        :fields="fields2"
        :responsive="true"
        outlined
      >
        <template #cell(action)="data">
          <b-button
            variant="primary"
            class="mr-1"
            @click="handleOverrideRow(data)"
            >上書き</b-button
          >
          <b-button variant="primary" @click="handleAddNewRow(data)"
            >新規登録</b-button
          >
        </template>
      </b-table>
      <template #modal-footer>
        <div class="d-flex justify-content-start" style="width: 100%">
          <b-button variant="primary" class="mr-1" @click="handleOverrideAll"
            >全て上書き</b-button
          >
          <b-button variant="primary" @click="handleAddNewAll"
            >全て新規登録</b-button
          >
        </div>
      </template>
    </b-modal>
    <b-modal
      id="add-modal"
      size="lg"
      title="ライセンス"
      @ok="handleAddLicense"
      cancel-title="キャンセル"
      centered
    >
      <b-form>
        <b-row v-show="false">
          <b-col cols="12" class="mb-md-0 mb-2">
            <b-form-group
              label-cols="6"
              label-cols-lg="5"
              label="ライセンスの種類:"
            >
              <el-radio v-model="form.licensetype" label="1">個人</el-radio>
              <el-radio v-model="form.licensetype" label="0">企業</el-radio>
              <!-- <b-form-input id="input-horizontal"></b-form-input> -->
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-show="false">
          <b-col cols="12" class="mb-md-0 mb-2">
            <b-form-group
              id="group-soluong"
              label-cols="6"
              label-cols-lg="5"
              label="数量:"
              label-for="input-soluong"
            >
              <el-input-number
                id="input-soluong"
                :disabled="form.licensetype == '1'"
                v-model="form.soluong"
                :min="1"
                :max="100"
              ></el-input-number>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" class="mb-md-0 mb-2">
            <b-form-group
              label-cols="6"
              label-cols-lg="5"
              id="group-ngaybatdau"
              label="使用開始日:"
            >
              <!-- <el-date-picker
                v-model="form.startDate"
                type="date"
                placeholder="Select startDate"
              >
              </el-date-picker> -->

              <div style="width: 200px !important" class="license-date-picker">
                <b-form-datepicker
                id="example-i18n-picker11"
                v-model="form.startDate"
                locale="ja-JP"
                calendarLocale="ja-JP"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
               
                ></b-form-datepicker>
              </div>

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" class="mb-md-0 mb-2">
            <b-form-group
              label-cols="6"
              label-cols-lg="5"
              id="group-thoihan"
              label="有効期限:"
            >
              <!-- <el-date-picker
                v-model="form.expDate"
                type="date"
                placeholder="Select ExpDate"
              >
              </el-date-picker> -->

              <div style="width: 200px !important" class="license-date-picker">
                <b-form-datepicker
                id="example-i18n-picker"
                v-model="form.expDate"
                locale="ja-JP"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                width="100"
              ></b-form-datepicker>
              </div>

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" class="mb-md-0 mb-2">
            <b-form-group
              id="group-giaban"
              label-cols="6"
              label-cols-lg="5"
              label="価格:"
            >
              <el-input-number
                v-model="form.price"
                :min="0"
                :max="100000000"
              ></el-input-number>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="mb-md-0 mb-2">
            <b-form-group
              id="group-giaban"
              label-cols="6"
              label-cols-lg="5"
              label=""
            >
              <b-form-checkbox id="checkbox-1" v-model="form.trial">
                Trial
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="mb-md-0 mb-2">
            <b-form-group id="group-ghichu" label="備考:">
              <b-form-textarea
                v-model="form.note"
                id="textarea-large"
                size="lg"
                placeholder=""
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <user-filter v-on:clickSearch="handleSearch" :filter="search" />
    <b-card no-body class="mb-0 p-1">
      <div class="d-flex justify-content-start mb-1">
        <!-- <b-button variant="primary" class="mr-1" @click="addUser">Template</b-button> -->
        <b-button
          variant="primary"
          @click="$refs.fileinput.$el.childNodes[0].click()"
        >
          <b-form-file
            v-model="fileexcel"
            ref="fileinput"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            class="d-none"
            @input="importLicense"
          >
          </b-form-file>
          インポート</b-button
        >
      </div>
      <b-table
        show-empty
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="0"
        :busy="isBusy"
        :responsive="true"
        outlined
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>読み込み中...</strong>
          </div>
        </template>
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(personal)="data">
          <!-- <b-button v-for="(item,index) in data.item.personalLicense" :key="index" @click="Test(data)">xx</b-button> -->
          <el-tag
            v-for="(item, index) in data.item.personalLicense"
            class="m-1"
            :key="index"
            closable
            @close="handleDelete(item.id)"
            style="cursor: pointer"
            @click="handleUpdateSelectedLicense(item, data.item, '1')"
          >
            <strong> {{ item.licenseNumber }} </strong>

            {{ !item.isStandard ? "Trial-" : "" }} 
            使用開始日：{{ formatDateString(item.startDate) }}
            有効期限:
            {{ formatDateString(item.expiredDate) }} 価格: {{ item.pricing }} ¥
          </el-tag>
        </template>
        <template #cell(company)="data">
          <!-- <b-button v-for="(item,index) in data.item.personalLicense" :key="index" @click="Test(data)">xx</b-button> -->
          <el-tag
            v-for="(item, index) in data.item.companyLicense"
            class="m-1"
            :key="index"
            closable
            @close="handleDelete(item.id)"
            style="cursor: pointer"
            @click="handleUpdateSelectedLicense(item, data.item, '0')"
          >
            <!-- Gói tool : {{ item.productName }} - HSD : -->
            {{ formatDateString(item.expiredDate) }} - SL :
            {{ item.numberOfSubUser }} - Price: {{ item.pricing }} vnd
          </el-tag>
        </template>
        <!-- Column: Actions -->
        <template #cell(action)="data">
          <b-button variant="primary" @click="openAddModal(data)">
            ライセンス追加
            <!-- <feather-icon icon="EditIcon" /> -->
          </b-button>
          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="btn-icon"
            @click="handleDelete(data)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button> -->
        </template>
      </b-table>
      <div class="mx-2 mt-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UserFilter from "../pages/admin/UserFilter.vue";
import axios from "@axios";
import moment from "moment";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BForm,
  BSpinner,
  BFormDatepicker,
  BFormCheckbox,
  BFormSelect,
  BFormFile,
  BFormTextarea,
} from "bootstrap-vue";

export default {
  components: {
    DataTable,
    Column,
    BForm,
    BFormTextarea,
    BFormDatepicker,
    BFormFile,
    BFormSelect,
    BFormCheckbox,
    BButton,
    BTable,
    BPagination,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BSpinner,
    UserFilter,
  },
  data() {
    return {
      items: [],
      fileexcel: null,
      soluong: 1,
      currentId: null,
      licenseOverrides: [],
      errorRows: [],
      form: {
        id: "",
        licensetype: "1",
        soluong: 1,
        expDate: null,
        startDate: null,
        price: 200000,
        note: "",
        trial: false,
      },
      search: {
        name: "",
        email: "",
        phone: "",
        company: "",
        department: "",
        address: "",
        position: "",
        start: "",
        end: "",
        isActive: "All",
      },
      fields: [
        {
          key: "index",
          label: "No.",
          thStyle: { width: "80px" },
        },
        {
          key: "fullName",
          label: "氏名",
          thStyle: { "min-width": "160px" },
        },
        {
          key: "email",
          label: "メールアドレス",
        },
        {
          key: "phone",
          label: "電話番号",
          thStyle: { width: "145px" },
        },
        {
          key: "personal",
          label: "個人",
        },

        // {
        //   key: "company",
        //   label: "企業",
        // },
        {
          key: "action",
          label: "アクション",
          thStyle: { "min-width": "210px" },
        },
      ],
      fields2: [
        {
          key: "row",
          label: "Row",
        },
        {
          key: "email",
          label: "メールアドレス",
        },
        {
          key: "price",
          label: "価格",
        },
        {
          key: "expDate",
          label: "有効期限",
        },
        {
          key: "note",
          label: "備考",
        },
        {
          key: "isTrial",
          label: "Is Trial",
        },
        {
          key: "action",
          label: "アクション",
        },
      ],
      currentPage: 1,
      totalUsers: 1,
      perPage: 10,
      totalItems: 1,
      isBusy: true,
      filter: "",
      isUpdate: false,
    };
  },
  async created() {
    this.isBusy = true;
    await this.getData();
    this.isBusy = false;
  },
  methods: {
    async handleAddNewAll() {
      let data = [...this.licenseOverrides];
      let payload = data.map((x) => ({
        id: x.userCommandLicenseId,
        email: x.email,
        expDate: x.expDate,
        startDate: x.startDate,
        price: x.price,
        note: x.note,
        isOverride: false,
        isTrial: x.isTrial,
      }));
      let res = await this.requestAddLicense(payload);
      if (res == true) {
        this.$bvModal.hide("license-override-modal");
      }
    },
    async handleOverrideAll() {
      let data = [...this.licenseOverrides];
      let payload = data.map((x) => ({
        id: x.userCommandLicenseId,
        email: x.email,
        expDate: x.expDate,
        startDate: x.startDate,
        price: x.price,
        note: x.note,
        isOverride: true,
        isTrial: x.isTrial,
      }));

      let res = await this.requestAddLicense(payload);
      if (res == true) {
        this.$bvModal.hide("license-override-modal");
      }
    },
    async handleAddNewRow(data) {
      let row = data.item;
      let item = {
        id: row.userCommandLicenseId,
        email: row.email,
        expDate: row.expDate,
        startDate: row.startDate,
        price: row.price,
        note: row.note,
        isOverride: false,
        isTrial: row.isTrial,
      };
      let payload = [];
      payload.push(item);

      let res = await this.requestAddLicense(payload);
      if (res == true) {
        this.licenseOverrides.splice(data.index, 1);
      }
    },
    async handleOverrideRow(data) {
      let row = data.item;
      let item = {
        id: row.userCommandLicenseId,
        email: row.email,
        expDate: row.expDate,
        price: row.price,
        note: row.note,
        isTrial: row.isTrial,
        isOverride: true,
      };
      let payload = [];
      payload.push(item);

      let res = await this.requestAddLicense(payload);
      if (res == true) {
        this.licenseOverrides.splice(data.index, 1);
      }
    },
    async requestAddLicense(payload) {
      try {
        let res = await axios.post("UserLicense/add-license-override", payload);
        if (res.data.succeeded) {
          let data = res.data.data;
          data.successMessages.forEach((e) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Success",
                text: e,
                variant: "success",
              },
            });
          });
          data.errorList.forEach((e) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Warning",
                text: e,
                variant: "danger",
              },
            });
          });

          if (data.overrideSuccess > 0 || data.addNewSuccess > 0) {
            await this.getData();
            return true;
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Warning",
            text: e.response.message,
            variant: "danger",
          },
        });
      }
    },
    async importLicense() {
      if (this.$refs.fileinput.files.length == 1) {
        try {
          var bodyFormData = new FormData();
          bodyFormData.append("file", this.$refs.fileinput.files[0]);
          let res = await axios({
            url: `UserLicense/import-license`,
            method: "POST",
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
          });
          if (res.data.succeeded) {
            let importSuccess = res.data.data.importSuccess;
            if (importSuccess && importSuccess > 0) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Success",
                  // text: `Thêm thành công ${importSuccess} license`,
                  text: `追加されたライセンス数：${importSuccess}`,
                  variant: "success",
                },
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "警告",
                  text: "ライセンスが追加されていません。",
                  variant: "danger",
                },
              });
            }
            if (res.data.data.errorRows.length > 0) {
              this.errorRows = res.data.data.errorRows;
              this.$bvModal.show("error-list-modal");
            }

            if (res.data.data.licenseOverrides.length > 0) {
              this.licenseOverrides = res.data.data.licenseOverrides;
              this.$bvModal.show("license-override-modal");
            }

            await this.getData();
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Warning",
              text: e.response.message,
              variant: "danger",
            },
          });
        }
        this.fileexcel = null;
      }
    },
    formatDateString(date) {
      let includeTime = "ja-JP";
      if (date) {
        const d = new Date(date);

        if (includeTime) {
          return d.toLocaleDateString(includeTime);
        }

        return d.toLocaleDateString(includeTime);
      }

      return "";
    },
    async handleDelete(id) {
      try {
        await axios.delete("UserLicense/" + id);
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "成功",
            text: "ライセンス削除が成功しました。",
            variant: "success",
          },
        });
        await this.getData();
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "失敗しました。",
            text: e.response.message,
            variant: "danger",
          },
        });
      }
    },
    async handleUpdateSelectedLicense(selectedLicense, row, type) {
      this.isUpdate = true;
      this.currentId = row.id;
      this.form.id = selectedLicense.id;
      this.form.licensetype = type;
      this.form.soluong = selectedLicense.numberOfSubUser;
      this.form.note = selectedLicense.note;
      this.form.price = selectedLicense.pricing;
      this.form.trial = !selectedLicense.isStandard;
      this.form.expDate = moment(selectedLicense.expiredDate).toDate();
      this.form.startDate = moment(selectedLicense.startDate).toDate();
      this.$bvModal.show("add-modal");
    },
    async handleAddLicense() {
      this.form.expDate = moment(this.form.expDate).toDate();
      this.form.startDate = moment(this.form.startDate).toDate();
      if(this.form.expDate.getTime() <= this.form.startDate.getTime()){
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "失敗しました。",
            text: "有効期限に使用開始日以降の日を入力してください",
            variant: "danger",
          },
        });
        return;
      }

      let data = { ...this.form };
    
      let payload = {
        id: data.id,
        expDate: new Date(data.expDate.getFullYear(), data.expDate.getMonth(), data.expDate.getDate() + 1),
        startDate: new Date(data.startDate.getFullYear(), data.startDate.getMonth(), data.startDate.getDate() + 1),
        isPersonalLicense: data.licensetype == "1" ? true : false,
        pricing: data.price,
        userId: this.currentId,
        note: data.note,
        isStandard: !data.trial,
        numberOfSubUser: data.soluong,
      };
      
      if (this.isUpdate == false) {
        try {
          payload.userId = data.id;

          let res = await axios.post("UserLicense", payload);
          if (res.status == 200) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "成功",
                text: "ライセンス追加が成功しました。",
                variant: "success",
              },
            });
            await this.getData();
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "失敗しました。",
                text: "ライセンス追加が失敗しました。",
                variant: "danger",
              },
            });
          }
        } catch {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "失敗しました。",
              text: "ライセンス追加が失敗しました。",
              variant: "danger",
            },
          });
        }
      } else {
        try {
          let res = await axios.put("UserLicense", payload);
          if (res.status == 200) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "成功",
                text: "ライセンス編集が成功しました。",
                variant: "success",
              },
            });
            await this.getData();
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "失敗しました。",
                text: "ライセンス編集が失敗しました。",
                variant: "danger",
              },
            });
          }
        } catch {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "失敗しました。",
              text: "ライセンス編集が失敗しました。",
              variant: "danger",
            },
          });
        }
      }
    },
    openAddModal(data) {
      this.isUpdate = false;
      this.form.expDate = new Date();
      this.form.startDate = new Date();
      // this.currentId = data.item.id;
      this.form.id = data.item.id;
      this.form.trial = false;
      this.$bvModal.show("add-modal");
    },
    async handleSearch() {
      await this.getData();
    },
    async getData() {
      try {
        let activeFilter = "";
        if (this.search.isActive == "True") {
          activeFilter = true;
        }
        if (this.search.isActive == "False") {
          activeFilter = false;
        }
        let payload = { ...this.search };
        payload.isActive = activeFilter;
        payload.pageIndex = this.currentPage;

        let res = await axios.get("CustomUser/all-user-license", {
          params: {
            ...payload,
          },
        });
        this.items = res.data.data.items;
        this.totalUsers = res.data.data.totalCount;
      } catch {}
    },
  },
  watch: {
    currentPage: {
      handler: async function(value) {
        this.isBusy = true;
        await this.getData();
        this.isBusy = false;
      },
    },
  },
};
</script>
<style></style>
