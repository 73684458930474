<template>
  <b-card no-body>
    <b-card-header class="pb-50 justify-content-between">
      <h5>フィルター</h5>
      <b-button variant="primary" @click="handleSearch">検索</b-button>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <!-- <label>Role</label> -->
          <b-form-group
            id="label-full-name"
            label="氏名 :"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
              v-model="filter.name"
              v-on:keyup.enter="handleSearch"
              placeholder="氏名で検索"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <!-- <label>Role</label> -->
          <b-form-group id="email" label="メールアドレス:">
            <b-form-input 
              v-model="filter.email"
              v-on:keyup.enter="handleSearch"
              placeholder="メールアドレスで検索"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <!-- <label>Role</label> -->
          <b-form-group id="label-phone" label="電話番号:" label-for="phone">
            <b-form-input
              id="phone"
              v-model="filter.phone"
              v-on:keyup.enter="handleSearch"
              placeholder="電話番号で検索"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <!-- <label>Role</label> -->
          <b-form-group id="label-company" label="会社名:" label-for="company">
            <b-form-input
              id="company"
              v-model="filter.company"
              v-on:keyup.enter="handleSearch"
              placeholder="会社名で検索"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <!-- <label>Role</label> -->
          <b-form-group id="label-position" label="役職:" label-for="position">
            <b-form-input
              id="position"
              v-model="filter.position"
              v-on:keyup.enter="handleSearch"
              placeholder="役職"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <!-- <label>Role</label> -->
          <b-form-group
            id="label-department"
            label="部署:"
            label-for="department"
          >
            <b-form-input
              id="department"
              v-model="filter.department"
              v-on:keyup.enter="handleSearch"
              placeholder="部署"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <!-- <label>Role</label> -->
          <b-form-group id="label-address" label="住所:" label-for="address">
            <b-form-input
              id="address"
              v-model="filter.address"
              v-on:keyup.enter="handleSearch"
              placeholder="住所"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <b-form-group
            id="label-active"
            label="アクティブ:"
            label-for="active"
          >
            <b-form-select
              v-model="filter.isActive"
              :options="activeoption"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <!-- <label>Role</label> -->
          <b-form-group
            id="label-create-start"
            label="から:"
            label-for="create-from"
          >
            <b-form-datepicker
              locale="ja"
              v-model="filter.start"
              id="create-from"
              class="mb-2"
              placeholder="日付が選択されていません。"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <!-- <label>Role</label> -->
          <b-form-group
            id="label-create-start"
            label="まで:"
            label-for="create-to"
          >
            <b-form-datepicker
              locale="ja"
              v-model="filter.end"
              id="create-to"
              class="mb-2"
              placeholder="日付が選択されていません。"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>
<script>
import vSelect from 'vue-select';
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormDatepicker,
  BFormSelect,
  BButton,
} from 'bootstrap-vue';
export default {
  data() {
    return {
      activeoption: ['オール', '解除', 'ロック'],
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BFormGroup,
    BFormDatepicker,
    vSelect,
    BFormSelect,
    BButton,
  },
  props: {
    filter: {
      type: Object,
    },
  },
  methods: {
    handleSearch() {
      this.$emit('clickSearch');
    },
  },
};
</script>
<style></style>
